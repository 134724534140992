<template>
  <div class="container" v-if="initOk">
    <van-divider
      v-if="id == undefined"
      :style="{
        borderColor: '#ee0a24',
        padding: '0 100px',
      }"
    >
      注意事项
    </van-divider>

    <van-notice-bar
      v-if="id == undefined"
      wrapable
      :scrollable="false"
      text="广西外国语学院承诺不会让任何一个学生因为经济困难失学，如有疑问请联系0771-4730985，谢谢！"
    />

    <van-notice-bar
      v-if="id && status == 'PENDING'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      text="您的申请我们已经收到，预计24小时内审核完毕。请耐心等待，谢谢！"
    />

    <van-notice-bar
      v-if="id && status == 'SUCCEED'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      text="您的申请已通过审核"
    />

    <van-notice-bar
      v-if="id && status == 'FAILED'"
      left-icon="volume-o"
      wrapable
      :scrollable="false"
      :text="approve"
    />

    <van-form @submit="onSubmit">
      <div>
        <p class="input-info">申请金额</p>
        <van-field
          v-model="amount"
          label="住宿费总金额"
          name="amount"
          type="number"
          input-align="right"
          readonly
        >
          <template #extra><span style="padding: 0 5px">元</span></template>
        </van-field>
        <p class="input-info">生源地信用助学贷款受理证明</p>
        <van-field
          name="uploader"
          :rules="[{ required: true, message: '请选择照片' }]"
        >
          <template #input>
            <van-uploader
              :disabled="id == undefined ? false : true"
              :deletable="id == undefined ? true : false"
              v-model="uploader"
              :after-read="afterRead"
              multiple
              :max-count="3"
              :max-size="5 * 1024 * 1024"
              @oversize="onOversize"
            />
          </template>
        </van-field>
        <div v-if="id == undefined" style="margin: 16px">
          <van-button round block type="danger" native-type="submit"
            >提交申请</van-button
          >
        </div>
        <div v-if="id && status == 'PENDING'" style="margin: 16px">
          <van-button
            round
            block
            type="default"
            native-type="button"
            @click="cancel()"
            >取消申请</van-button
          >
        </div>
        <!-- <div v-else-if="id && status == 'SUCCEED'" style="margin: 16px">
            <van-button
              round
              block
              type="default"
              native-type="button"
              @click="cancel()"
              >取消申请</van-button
            >
          </div> -->
        <div v-else-if="id && status == 'FAILED'" style="margin: 16px">
          <van-button
            round
            block
            type="default"
            native-type="button"
            @click="cancel()"
            >重新申请</van-button
          >
        </div>
      </div>
    </van-form>
    <br />
  </div>
</template>
<script>
import { Toast } from "vant";
import { Notify } from "vant";
import { Dialog } from "vant";
import * as api from "@/apis/api";
export default {
  data() {
    return {
      initOk: false,
      id: undefined,
      status: undefined,
      memo: undefined,
      approve: undefined,
      amount: 0,
      uploader: [],
    };
  },
  created() {
    this.findAmount();
  },
  methods: {
    findAmount() {
      api.hostel().then((res) => {
        if (res.state == "fail") {
          this.$router.push({ path: "/checkin/hostel", query: {} });
        }
        this.amount = res.fee;
        this.findHostelFeeReq();
      });
    },
    //上传
    onOversize(file) {
      console.log(file);
      Toast.fail("文件大小不能超过5MB");
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let formData = new FormData();
      formData.append("file", file.file);
      api.commonUpload(formData).then((res) => {
        if (res.state == "ok") {
          file.status = "succeed";
          file.message = "上传成功";
          file.url = res.msg;
        } else {
          file.status = "failed";
          file.message = "上传失败";
        }
      });
    },
    //上传END
    findHostelFeeReq() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      api.hostelFeeReq().then((res) => {
        if (res.id) {
          this.id = res.id;
          this.status = res.status;
          this.uploader = [];
          if (res.pics) {
            JSON.parse(res.pics).forEach((r) => {
              this.uploader.push({ url: r });
            });
          }
          this.memo = res.memo;
          this.approve = res.approve;
        } else {
          this.id = undefined;
        }
        Toast.clear();
        this.initOk = true;
      });
    },
    onSubmit(values) {
      values.fee = this.amount;

      let pics = [];
      this.uploader.forEach((u) => {
        if (u && u != null && u.url && u.url != null) {
          pics.push(u.url);
        }
      });
      if (pics.length < 1) {
        Notify({ type: "danger", message: "请上传证明材料" });
        return;
      }
      values.uploader = [];
      values.pics = JSON.stringify(pics);

      api.insertHostelFeeReq(values).then((res) => {
        if (res.state == "ok") {
          Toast.success("提交成功，请等待审核");
          // this.findHostelFeeReq();
          this.$router.push({ path: "/checkin/hostel", query: {} });
        } else {
          Notify({ type: "danger", message: res.msg });
        }
      });
      console.log("submit", values);
    },
    cancel() {
      Dialog.confirm({
        title: "提示",
        message: "您确认取消吗？",
      })
        .then(() => {
          api.cancelHostelFeeReq().then((res) => {
            if (res.state == "ok") {
              this.findHostelFeeReq();
              Toast.success("成功取消");
            } else {
              Notify({ type: "danger", message: res.msg });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px 0px 10px 0px;
}
.input-info {
  padding: 10px;
  margin: 0;
  font-size: 14px;
}
.van-checkbox {
  margin-bottom: 10px;
  font-size: 14px;
}
.van-checkbox:last-child {
  margin-bottom: 0px;
}
.spec-tip {
  padding: 10px;
  color: #ed6a0c;
  background-color: #fffbe8;
  font-size: 14px;
  line-height: 24px;
}
.spec-tip a {
  color: red;
}
.req-money {
  padding: 10px;
  display: flex;
  line-height: 30px;
}
</style>
